
export const getLocalization = (nameArray: any[], currentLanguage: string): string => {
    if (!nameArray) {
      return "NULL";
    }
    else if (nameArray.length === 0) {
      return "NO NAME";
    }
    else {
      let object;
      if (currentLanguage === "fi") {
        object = nameArray.find((value, index, obj) => {return value.locale === "Finnish"});
        return object !== undefined ? object.value : "NO VALUE"; 
      }
      else {
        object = nameArray.find((value, index, obj) => {return value.locale === "English"});
        return object !== undefined ? object.value : "NO VALUE"; 
      }
    }
  }
  