import React, { ComponentState } from 'react';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import './WasteFacilityEditContainer.css';
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from 'react-router';
import { Translation } from "react-i18next";
import { GoogleMap, LoadScript, Marker, StandaloneSearchBox } from '@react-google-maps/api';
import Axios from 'axios';
import { getLocalization } from '../../Utils/Helpers/localizationUtils';
import { WasteFacilityEditState, editParams } from './IWasteFacilityEditStates';

type HomeProps = RouteComponentProps

const typePath = process.env.REACT_APP_FACILITYTYPES || "";
const facilityPath = process.env.REACT_APP_FACILITIES || "";
const wasteTypePath = process.env.REACT_APP_WASTETYPES || "";
const placesLibrary: ("places"[]) = ["places"];
const parentPagePath = "/admin/facilities";

class WasteFacilityEdit extends React.Component<HomeProps, WasteFacilityEditState> {

  private searchBox: any;
  constructor(props: HomeProps) {   
    super(props);
    this.HandleChange = this.HandleChange.bind(this);
    let idParam: editParams = this.props.match.params;
    this.state = {
      id: idParam.id || 0,
      facilityTypes: [],
      chosenType: "novalue",
      name: "",
      address: "",
      longitude: 29.763565942382456,
      latitude: 62.60102802608426,
      wasteTypes: [],
      checkboxArray: new Array(100).fill(false)
    }
  }

  public componentDidMount() {
    this.GetFacilityTypes();
    this.GetWasteTypes();
  }

  private GetFacility(id: number) {
    Axios.get(facilityPath + "/" + id)
      .then((data) => {
        let chosenWasteTypes = this.state.checkboxArray;
        for (let i = 0; i < data.data.wasteTypeIds.length; i++) {
          let checkboxIndex = this.state.wasteTypes.findIndex((value) => {return value.id === data.data.wasteTypeIds[i]});
          if (checkboxIndex >= 0 && checkboxIndex < chosenWasteTypes.length) {
            chosenWasteTypes[checkboxIndex] = true;
          }
        }
        this.setState({
          chosenType: data.data.wasteFacilityTypeId,
          latitude: data.data.latitude,
          longitude: data.data.longitude,
          name: data.data.name,
          address: data.data.address,
          checkboxArray: chosenWasteTypes
        })
      })
      .catch((error) => {
        console.log(error);
      });
  }

  private SetFacility() {
    let selectedTypes: any[] = [];
    for (let i = 0; i < this.state.checkboxArray.length; i++) {
      if (this.state.checkboxArray[i] === true) {
        selectedTypes.push(this.state.wasteTypes[i].id);
      }
    }

    let data = {
      wasteFacilityTypeId: this.state.chosenType,
      name: this.state.name,
      address: this.state.address,
      longitude: this.state.longitude,
      latitude: this.state.latitude,
      wasteTypeIDs: selectedTypes
    };

    if (this.state.id === 0) {
      Axios.post(facilityPath, data).then((inc) => {
        this.props.history.push(parentPagePath);
      })
      .catch((error) => {
        console.log(error);
      })
    }
    else {
      Axios.put(facilityPath + "/" + this.state.id, data).then((inc) => {
        this.props.history.push(parentPagePath);
      })
      .catch((error) => {
        console.log(error);
      })
    }
  }

  private GetFacilityTypes() {
    Axios.get(typePath)
      .then((data) => {
        this.setState({facilityTypes: data.data.wasteFacilityTypes});
      })
      .catch((error) => {
        console.log(error);
      });
  }

  
  private GetWasteTypes() {
    Axios.get(wasteTypePath)
      .then((data) => {
        let list: any[] = data.data.wasteTypes;
        let orderedList = list.sort((a, b) => {
          let textA = getLocalization(a.names, "fi").toUpperCase();
          let textB = getLocalization(b.names, "fi").toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.setState({wasteTypes: orderedList});

        if (this.state.id !== 0) {
          this.GetFacility(this.state.id);
        }
      })
      .catch((error) => {
        console.log(error)
      });
  }

  private SelectChange(values: any) {
    this.setState({chosenType: values.target.value})
  }

  private HandleChange(event: any) {
    const id = event.target.id;
    const value = event.target.value;
    this.setState({[id]: value} as ComponentState);
  }

  private HandleCheckboxChange(event: any, index: number) {
    let changedArray = this.state.checkboxArray;
    changedArray[index] = !changedArray[index];
    this.setState({checkboxArray: changedArray});
  }

  private SetCoordinates(e: any) {
    this.setState({
      latitude: e.latLng.lat(),
      longitude: e.latLng.lng()
    });
  }

  public render() {
    return (
      <Translation> 
      {
        (t, {i18n}) => {
          return (
            <div>
              <div>
                <div className="input-margin">
                  <Select
                    placeholder={t("FACILITY_TYPE")}
                    value={this.state.chosenType}
                    onChange={(value) => {this.SelectChange(value)}}
                  >
                    <MenuItem value="novalue">{t("CHOOSE_FACILITY_TYPE")}</MenuItem>
                    {
                      this.state.facilityTypes.map((facility) => {
                        return <MenuItem key={facility.id} value={facility.id}>{getLocalization(facility.names, i18n.language)}</MenuItem>
                      })
                    }
                  </Select>
                </div>
              </div>
              <div className="horizontal-content">
                <div className="input-margin">
                  <TextField
                    value={this.state.name}
                    id="name"
                    label={t("FACILITY_NAME")}
                    onChange={(event) => this.HandleChange(event)}
                    placeholder={t("FACILITY_NAME")}
                    fullWidth
                  />
                </div>
                <div className="input-margin">
                  <TextField
                    value={this.state.address}
                    id="address"
                    label={t("FACILITY_ADDRESS")}
                    onChange={(event) => this.HandleChange(event)}
                    placeholder={t("FACILITY_ADDRESS")}
                    fullWidth
                  />
                </div>
              </div>
              <div className="horizontal-content">
                <div className="input-margin">
                  <TextField
                    value={this.state.longitude}
                    id="longitude"
                    label={t("LONGITUDE")}
                    onChange={(event) => this.HandleChange(event)}
                    placeholder={t("LONGITUDE")}
                    fullWidth
                    disabled
                  />
                </div>
                <div className="input-margin">
                  <TextField
                    value={this.state.latitude}
                    id="latitude"
                    label={t("LATITUDE")}
                    onChange={(event) => this.HandleChange(event)}
                    placeholder={t("LATITUDE")}
                    fullWidth
                    disabled
                  />
                </div>
              </div>
              <LoadScript
                id="script-loader"
                googleMapsApiKey="AIzaSyBb0kwLjazn6NGuP5L6-_E6_9aLe_o1FW8"
                libraries={placesLibrary}
                language={i18n.language}
              >
                <GoogleMap
                  id="facility-map"
                  mapContainerClassName="map-size"
                  clickableIcons={false}
                  onClick={(e) => {this.SetCoordinates(e)}}
                  center={{
                    lat: this.state.latitude,
                    lng: this.state.longitude
                  }}
                  zoom={9}
                >
                  <Marker
                    position={{
                      lat: this.state.latitude,
                      lng: this.state.longitude
                    }}
                    visible={true}
                  />
                  <StandaloneSearchBox
                    onLoad={ref => this.searchBox = ref}
                    onPlacesChanged={
                      () => {
                        if (this.searchBox.getPlaces().length > 0) {
                          this.setState({latitude: this.searchBox.getPlaces()[0].geometry.location.lat()});
                          this.setState({longitude: this.searchBox.getPlaces()[0].geometry.location.lng()});
                          if (this.state.address === "") {
                            this.setState({address: this.searchBox.getPlaces()[0].name})
                          }
                        }
                      }
                    }
                  >
                    <input
                      type="text"
                      placeholder={t("ADDRESS_SEARCH")}
                      style={{
                        boxSizing: `border-box`,
                        border: `1px solid transparent`,
                        width: `240px`,
                        height: `32px`,
                        padding: `0 12px`,
                        borderRadius: `3px`,
                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                        fontSize: `14px`,
                        outline: `none`,
                        textOverflow: `ellipses`,
                        position: "absolute",
                        left: "50%",
                        marginLeft: "-120px"
                      }}
                    />
                  </StandaloneSearchBox>
                </GoogleMap>
              </LoadScript>
              <div style={{height: "20px"}} />
              <div>
                <FormGroup row>
                  {
                    this.state.wasteTypes.map((wasteType, index) => {
                      return (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox id="checkboxTest" checked={this.state.checkboxArray[index]} onChange={(event) => this.HandleCheckboxChange(event, index)} value="checkboxTest" />
                        }
                        label={getLocalization(wasteType.names, i18n.language)}
                      />
                      )
                    })
                  }
                </FormGroup>
              </div>
              <Button
                variant="contained"
                onClick={this.SetFacility.bind(this)}
              >
                {t("UPLOAD_FACILITY")}
              </Button>
            </div>
          )
        }
      }
      </Translation>
    );
  }
}

export const WasteFacilityEditContainer = withRouter(WasteFacilityEdit)
export default WasteFacilityEditContainer