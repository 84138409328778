import React, { ComponentState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import './ReservationDay.css';
import { Translation } from 'react-i18next';
import moment from 'moment';
import axios from 'axios';
import 'moment/locale/fi';
import Booking from '../Booking/Booking';
import { ReservationDayStates, ReservationDayProps } from './IReservationDay';

const path = process.env.REACT_APP_RESERVATIONS || "";
const equipmentPath = process.env.REACT_APP_EQUIPMENT || "";

class ReservationDay extends React.Component<ReservationDayProps, ReservationDayStates> {
    
    constructor(props: any) {
        super(props);
        moment.locale("fi");
        this.state = {
            chosenTrailerDate: 0,
            chosenContainerDate: 0,
            lastName: "",
            phoneNumber: "",
            reserveOptions: ["RESERVE_TRAILER", "RESERVE_CONTAINER"],
            chosenEquipmentId: 0,
            fullDayTrailer: false,
            containerEndDate: new Date(),
            allEquipments: []
        }
    }

    public componentDidMount() {
        this.GetEquipment();
      }

    private ReserveTrailer(dayError: string) {
        let startDate, endDate;
        if (this.state.chosenEquipmentId === 0) {
            startDate = this.props.availableTimes[this.state.chosenTrailerDate].start;
            endDate = this.props.availableTimes[this.state.chosenTrailerDate].end;
        }
        else {
            startDate = moment(this.props.date).add(1, "day").unix();
            endDate = moment(this.props.containerEndDateList[this.state.chosenContainerDate]).add(1, "day").unix();
        }
        let data = {
            equipmentTypeId: this.state.chosenEquipmentId,
            start: startDate,
            end: endDate,
            allDay: this.state.fullDayTrailer,
            lastName: this.state.lastName,
            phoneNumber: this.state.phoneNumber,
            languageCode: "fi"
        }
        if (data.lastName !== "" && data.phoneNumber !== "")
        {
            axios.post(path, data).then(msg => {
                if (this.props.refreshFunction !== null) {
                    this.props.refreshFunction();
                }
            }).catch(error => {
                if (this.props.refreshFunction !== null) {
                    this.props.refreshFunction();
                }
                let message: string = error.message;
                if (message.includes("409")) {
                    alert(dayError);
                }
            });
        }
    }

    private GetEquipment() {
        axios.get(equipmentPath).then(msg => {
            this.setState({allEquipments: msg.data.equipments})
        })
    }

    private SelectChange(values: any) {
        this.setState({chosenTrailerDate: values.target.value})
    }
    private SelectEquipmentChange(values: any) {
        this.setState({chosenEquipmentId: values.target.value})
    }
    private SelectContainerChange(values: any) {
        this.setState({chosenContainerDate: values.target.value})
    }

    private HandleChange(event: any) {
        const id = event.target.id;
        const value = event.target.value;
        this.setState({[id]: value} as ComponentState);
    }

    private HandleCheckboxChange(event: any) {
        const id = event.target.id;
        const value = event.target.checked;
        this.setState({[id]: value} as ComponentState);
    }

    public render() {
        return (
            <Translation>
                {
                    t => {
                        return (
                            <div>
                                <h2>{moment(this.props.date).format("D.MM.YYYY")}</h2>
                                <div>
                                    <div style={{margin: "18px"}}>
                                        <Select
                                            value={this.state.chosenEquipmentId}
                                            onChange={this.SelectEquipmentChange.bind(this)}
                                        >
                                        {
                                            this.state.reserveOptions.map((option, index) => {
                                                return <MenuItem key={index} value={index}>{t(option)}</MenuItem>
                                            })
                                        }
                                        </Select>
                                    </div>
                                    { this.state.chosenEquipmentId === 0 &&
                                    <div>
                                        { this.props.availableTimes.length > 0 &&
                                        <div>
                                            <span style={{marginRight: "5px"}}>{t("SELECT_TIMESLOT")}:</span>
                                            <Select
                                                value={this.state.chosenTrailerDate}
                                                onChange={this.SelectChange.bind(this)}
                                                disabled={this.state.fullDayTrailer}
                                            >
                                            {
                                                this.props.availableTimes.map((slot, index) => {
                                                    return <MenuItem key={index} value={index}>{moment.unix(slot.start).format("HH:mm") + " - " + moment.unix(slot.end).format("HH:mm")}</MenuItem>
                                                })
                                            }
                                            </Select>
                                            <span style={{marginLeft: "15px"}}>
                                              <FormControlLabel
                                                  control={
                                                      <Checkbox
                                                          id="fullDayTrailer"
                                                          checked={this.state.fullDayTrailer}
                                                          onChange={event => this.HandleCheckboxChange(event)}
                                                          value={this.state.fullDayTrailer} />
                                                  }
                                                  label={t("FULL_DAY")}
                                              />
                                            </span>
                                        </div>
                                        }
                                        { this.props.availableTimes.length === 0 &&
                                        <h3>{t("NO_AVAILABLE_TIMESLOTS")}</h3>
                                        }
                                    </div>
                                    }
                                    { this.state.chosenEquipmentId === 1 &&
                                    <div>
                                        { this.props.availableTimesContainer.length > 0 &&
                                        <>
                                            <span>{t("RESERVE_CONTAINER")} {moment(this.props.date).format("D.MM.YYYY")} - </span>
                                            <Select
                                                value={this.state.chosenContainerDate}
                                                onChange={this.SelectContainerChange.bind(this)}
                                            >
                                            {
                                                this.props.containerEndDateList.map((endDate, index) => {
                                                    return <MenuItem key={index} value={index}>{moment(endDate).format("DD.MM.YYYY")}</MenuItem>
                                                })
                                            }
                                            </Select>
                                        </>
                                        }
                                        { this.props.availableTimesContainer.length === 0 &&
                                            <h3>{t("NO_AVAILABLE_TIMESLOTS")}</h3>
                                        }
                                    </div>
                                    }
                                    <div style={{margin: "10px"}}>
                                    <TextField
                                        value={this.state.lastName}
                                        id="lastName"
                                        label={t("LASTNAME")}
                                        onChange={(event) => this.HandleChange(event)}
                                        placeholder={t("LASTNAME")}
                                    />
                                    </div>
                                    <div style={{margin: "10px"}}>
                                    <TextField
                                        value={this.state.phoneNumber}
                                        id="phoneNumber"
                                        label={t("PHONE_NUMBER")}
                                        onChange={(event) => this.HandleChange(event)}
                                        placeholder={t("PHONE_NUMBER")}
                                    />
                                    </div>
                                    <div style={{margin: "10px"}}>
                                        <Button
                                            disabled={(this.props.availableTimes.length === 0 && this.state.chosenEquipmentId === 0) || this.state.lastName === "" || this.state.phoneNumber === ""}
                                            variant="contained"
                                            onClick={event => {this.ReserveTrailer(t("NO_FREE_FULL_DAY"))}}
                                        >
                                            {t("RESERVE")}
                                        </Button>
                                    </div>
                                </div>
                                <div className="bookings-list">
                                {
                                    this.props.bookings.map((booking, index) => {
                                        return <Booking key={index} booking={booking} refreshFunction={this.props.refreshFunction} />
                                    })
                                }
                                </div>
                            </div>
                        )
                    }
                }
            </Translation>
        );
    }
}

export default ReservationDay;
