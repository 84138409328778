import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
    en: {
        translations: {
            "USERNAME": "Username",
            "PASSWORD": "Password",
            "LOGIN": "Login",
            "TEST": "Test",
            "USERS": "Users",
            "CUSTOMERS": "Customers",
            "LOGOUT": "Logout",
            "EMAIL": "Email",
            "ROLE": "Role",
            "ACTIONS": "Actions",
            "ADD": "Add",
            "DELETE": "Delete",
            "FILTER": "Filter",
            "EDIT": "Edit",
            "CONFIRM_DELETE_ROW": "Are you sure delete this row?",
            "CANCEL": "Cancel",
            "SAVE": "Save",
            "SEARCH": "Search",
            "NAME": "Name",
            "LASTNAME": "Surname",
            "ADDRESS": "Address",
            "PHONE_NUMBER": "Phone number",
            "CUSTOMER_TYPE": "Customer type",
            "CUSTOMER_NUMBER": "Customer number",
            "DELETED": "Deleted",
            "VERIFIED": "Verified",
            "TRUE": "True",
            "FALSE": "False",
            "DIRECT": "Direct",
            "INDIRECT": "Indirect",
            "WASTEFACILITIES": "Waste Facilities",
            "FACILITY_TYPE": "Facility type",
            "FACILITY_NAME": "Facility name",
            "FACILITY_ADDRESS": "Facility address",
            "CHOOSE_FACILITY_TYPE": "Choose facility type",
            "LONGITUDE": "Longitude",
            "LATITUDE": "Latitude",
            "UPLOAD_FACILITY": "Update Data",
            "ECOPOINT": "Eco point",
            "WASTE_STATION": "Waste station",
            "WASTE_FACILITY": "Waste facility",
            "WASTE_TYPES": "Waste types",
            "WASTE_TYPE": "Waste type",
            "WASTE_ITEMS": "Waste items",
            "FINNISH_LABEL": "Finnish label",
            "ENGLISH_LABEL": "English label",
            "SELECT_TYPE": "Choose waste type",
            "TYPE_DELETED": "Waste type deleted!",
            "ERROR_CREATE_USER": "Error when creating user. Password must be at least 8 characters long, have a capital letter, number and special character.",
            "ERROR_USER_PASSWORD": "Wrong email or password.",
            "ADDRESS_SEARCH": "Address search",
            "FRONT_PAGE": "Front page",
            "SCAN_COMPLETED": "Scan completed!",
            "START_SCAN": "Start scan",
            "VISIT_CODE_READER": "Visit code reader",
            "CHECKING_CODE": "Verifying code...",
            "FREE_VISIT": "Free visit",
            "CONFIRM_DELETE": "Are you sure you want to delete: ",
            "FRONTPAGE_HEADER": "Puhas mobile application administration page",
            "ADMINISTRATOR": "Administrator",
            "RESERVATION_CALENDAR": "Reservation Calendar",
            "RESERVE_CONTAINER": "Reserve container",
            "RESERVE_TRAILER": "Reserve trailer",
            "CALENDAR": "Calendar",
            "TRAILER": "Trailer",
            "CONTAINER": "Container",
            "SELECT_TRAILER": "Select trailer",
            "SELECT_CONTAINER": "Select container",
            "RESERVE": "Reserve",
            "RESERVATION": "Reservation",
            "NO_AVAILABLE_TIMESLOTS": "No available timeslots",
            "TIME": "Time",
            "EQUIPMENT_NAME": "Equipment name",
            "EQUIPMENT_TYPE": "Equipment type",
            "SELECT_TIMESLOT": "Select timeslot",
            "FULL_DAY": "Koko päivä",
            "SCORE": "Score",
            "REVIEWS": "Reviews",
            "REVIEW_DATE": "Review date",
            "USER_GUIDES": "User guides",
            "HEADER": "Header",
            "NO_FREE_FULL_DAY": "Impossible to reserve. Container is not available between time intervals."
        }
    },
    fi: {
        translations: {
            "USERNAME": "Käyttäjätunnus",
            "PASSWORD": "Salasana",
            "LOGIN": "Kirjaudu sisään",
            "TEST": "Testi",
            "USERS": "Käyttäjät",
            "CUSTOMERS": "Asiakkaat",
            "LOGOUT":" Kirjaudu ulos",
            "EMAIL": "Sähköposti",
            "ROLE": "Rooli",
            "ACTIONS": "Toiminnot",
            "ADD": "Lisää",
            "DELETE": "Poista",
            "FILTER": "Suodata",
            "EDIT": "Muokkaa",
            "CONFIRM_DELETE_ROW": "Haluatko poistaa tämän rivin?",
            "CANCEL": "Peruuta",
            "SAVE": "Tallenna",
            "SEARCH": "Etsi",
            "NAME": "Nimi",
            "LASTNAME": "Sukunimi",
            "ADDRESS": "Osoite",
            "PHONE_NUMBER": "Puhelinnumero",
            "CUSTOMER_TYPE": "Asiakastyyppi",
            "CUSTOMER_NUMBER": "Asiakasnumero",
            "DELETED": "Poistettu",
            "VERIFIED": "Todennettu",
            "TRUE": "Kyllä",
            "FALSE": "Ei",
            "DIRECT": "Suora",
            "INDIRECT": "Epäsuora",
            "WASTEFACILITIES": "Jätekeskukset",
            "FACILITY_TYPE": "Jätekeskuksen tyyppi",
            "FACILITY_NAME": "Laitoksen nimi",
            "FACILITY_ADDRESS": "Laitoksen osoite",
            "CHOOSE_FACILITY_TYPE": "Valitse laitostyyppi",
            "LONGITUDE": "Pituusaste",
            "LATITUDE": "Leveysaste",
            "UPLOAD_FACILITY": "Päivitä tiedot",
            "ECOPOINT": "Ekopiste",
            "WASTE_STATION": "Jäteasema",
            "WASTE_FACILITY": "Jätekeskus",
            "WASTE_TYPES": "Jätetyypit",
            "WASTE_TYPE": "Jätetyyppi",
            "WASTE_ITEMS": "Jäte-esineet",
            "FINNISH_LABEL": "Nimi suomeksi",
            "ENGLISH_LABEL": "Nimi englanniksi",
            "SELECT_TYPE": "Valitse jätetyyppi",
            "TYPE_DELETED": "Jätetyyppi poistettu!",
            "ERROR_CREATE_USER": "Virhe käyttäjää luodessa. Salasanan tulee olla vähintään 8 merkkiä pitkä ja sisältää ison kirjaimen, numeron ja erikoismerkin.",
            "ERROR_USER_PASSWORD": "Väärä sähköposti tai salasana.",
            "ADDRESS_SEARCH": "Osoitehaku",
            "FRONT_PAGE": "Etusivu",
            "SCAN_COMPLETED": "Skannaus suoritettu!",
            "START_SCAN": "Aloita skannaus",
            "VISIT_CODE_READER": "Käyntikoodin lukija",
            "CHECKING_CODE": "Tarkistetaan koodia...",
            "FREE_VISIT": "Ilmainen käynti",
            "CONFIRM_DELETE": "Haluatko varmasti poistaa: ",
            "FRONTPAGE_HEADER": "Puhas mobiilisovelluksen ylläpito",
            "ADMINISTRATOR": "Järjestelmänvalvoja",
            "RESERVATION_CALENDAR": "Varauskalenteri",
            "RESERVE_CONTAINER": "Varaa lava",
            "RESERVE_TRAILER": "Varaa peräkärry",
            "CALENDAR": "Kalenteri",
            "TRAILER": "Peräkärry",
            "CONTAINER": "Lava",
            "SELECT_TRAILER": "Valitse peräkärry",
            "SELECT_CONTAINER": "Valitse lava",
            "RESERVE": "Varaa",
            "RESERVATION": "Varaus",
            "NO_AVAILABLE_TIMESLOTS": "Ei avoimia aikoja",
            "TIME": "Aika",
            "EQUIPMENT_NAME": "Varusteen nimi",
            "EQUIPMENT_TYPE": "Varustetyyppi",
            "SELECT_TIMESLOT": "Valitse aika",
            "FULL_DAY": "Koko päivä",
            "SCORE": "Pisteet",
            "REVIEWS": "Arvostelut",
            "REVIEW_DATE": "Arvostelun päivämäärä",
            "USER_GUIDES": "Käyttäjäoppaat",
            "HEADER": "Otsikko",
            "NO_FREE_FULL_DAY": "Ei mahdollista lainata; lava ei ole vapaana kokonaan aikavälissä."
        }
    }
}

i18n
  .use(initReactI18next)
  .init({
    // we init with resources
    resources,
    lng: "fi",
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;