import React from 'react';
import './ReservationCalendarContainer.css';
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from 'react-router';
import { Translation } from "react-i18next";
import Calendar from 'react-calendar';
import axios from 'axios';
import ReservationDay from '../../Components/ReservationDay/ReservationDay';
import moment from 'moment';
import ReservationCalendarStates from './IReservationCalendarStates';

type HomeProps = RouteComponentProps

const path = process.env.REACT_APP_RESERVATIONS || "";
const availableDatesPath = path + "/availabledates";
const availableTimesPath = path + "/availabletimes";
const equipmentPath = process.env.REACT_APP_EQUIPMENT || "";

class ReservationCalendar extends React.Component<HomeProps, ReservationCalendarStates> {

  constructor(props: HomeProps) {
    
    super(props);
    this.state = {
      currentDate: new Date(),
      allBookings: [],
      currentBookings: [],
      currentAvailableTimes: [],
      currentAvailableTimesContainer: [],
      nextThirtyDays: [],
      equipments: [],
      openDates: [],
      openDatesContainer: []
    };
  }

  public componentDidMount() {
    this.GetEquipment();
    this.GetAvailableDates(0);
    this.GetAvailableDates(1);
  }

  public RefreshBookings() {
    this.GetCurrentBookings();
    this.GetAvailableDates(0);
    this.GetAvailableDates(1);
    this.GetAvailableTimes(0, this.state.currentDate);
    this.GetAvailableTimes(1, this.state.currentDate);
  }

  private GetBookings() {
    axios.get(path).then(data => {
      let rawBookings: any[] = data.data.bookings;
      for (let i = 0; i < rawBookings.length; i++) {
        let equipment = this.state.equipments.find((eq) => parseInt(eq.id) === rawBookings[i].equipmentId);
        if (equipment !== undefined) {
          rawBookings[i].equipmentName = equipment.name;
          rawBookings[i].equipmentType = equipment.equipmentType.displayName;
        }
        else {
          rawBookings[i].equipmentName = "null";
          rawBookings[i].equipmentType = "null";
        }
      }
      this.setState({allBookings: rawBookings})
      this.SelectDate(this.state.currentDate);
    })
  }

  private GetEquipment() {
    axios.get(equipmentPath).then(msg => {
      this.setState({equipments: msg.data.equipments});
      this.GetBookings();
    })
  }

  private GetCurrentBookings() {
    axios.get(path).then(data => {
      this.setState({allBookings: data.data.bookings})
      this.GetDayBookings(this.state.currentDate);
      this.GetEquipment();
    })
  }

  private GetAvailableDates(equipmentId: number) {
    axios.get(availableDatesPath + "?equipmentTypeId=" + equipmentId).then(data => {
      if (equipmentId === 0) {
        this.setState({openDates: data.data.availableDates});
      }
      else if (equipmentId === 1) {
        this.setState({openDatesContainer: data.data.availableDates})
      }
    })
  }

  private GetAvailableTimes(equipmentId: number, date: Date) {
    axios.get(availableTimesPath + "?equipmentTypeId=" + equipmentId + "&start=" + moment(date).add(1, "day").unix() + "&end=" + moment(date).add(1, "day").unix()).then(msg => {
      if (equipmentId === 0) {
        this.setState({currentAvailableTimes: msg.data.timeSlots});
      }
      else if (equipmentId === 1) {
        this.setState({currentAvailableTimesContainer: msg.data.timeSlots});
      }
      
    })
  }

  private SelectDate(date: any) {
    let days: any[] = [];
    let movingDate = moment(date);
    for (let i = 0; i < 31; i++) {
      days.push(movingDate.format());
      movingDate.add(1, "day");
    }
    this.setState({currentDate: date, nextThirtyDays: days})
    this.GetAvailableTimes(0, date);
    this.GetAvailableTimes(1, date);
    this.GetDayBookings(date);
  }

  private GetDayBookings(date: any) {
    let dayBookings: any[] = [];
    for (let i = 0; i < this.state.allBookings.length; i++) {
      if (moment(date).isSameOrAfter(this.state.allBookings[i].startTime, "day") && moment(date).isSameOrBefore(this.state.allBookings[i].endTime, "day")) {
        dayBookings.push(this.state.allBookings[i]);
      }
    }
    this.setState({currentBookings: dayBookings});
  }

  private GetStatus(trailer: string, container: string, date: Date, show: boolean) {
    if (show === true && moment(date).isSameOrAfter(moment(moment().subtract(1, "day")))) {
      let tFirst = trailer.substring(0,1);
      let cFirst = container.substring(0, 1);
      let tStyle, cStyle;
      let tOpen = this.state.openDates.find(open => moment.unix(open).isSame(moment(date), "day"));
      if (tOpen !== undefined) {
        tStyle = {color: "green"};
      }
      else {
        tStyle = {color: "red"};
      }
      let cOpen = this.state.openDatesContainer.find(open => moment.unix(open).isSame(moment(date), "day"));
      if (cOpen !== undefined) {
        cStyle = {color: "green"};
      }
      else {
        cStyle = {color: "red"};
      }

      return <><span style={tStyle}> {tFirst}</span><span style={cStyle}>{cFirst}</span></>;
    }
    else {
      return <></>;
    }
  }

  public render() {
    return (
      <Translation> 
      {
        t => {
          return (
            <div className="centered-text-header">
              <h1>{t("CALENDAR")}</h1>
              <Calendar 
                onClickDay={date => this.SelectDate(date)}
                activeStartDate={this.state.currentDate}
                tileContent={(date) => {
                  return this.GetStatus(t("TRAILER"), t("CONTAINER"), date.date, date.view === "month")
                }}
                
              />
              <ReservationDay
                date={this.state.currentDate}
                bookings={this.state.currentBookings}
                availableTimes={this.state.currentAvailableTimes}
                availableTimesContainer={this.state.currentAvailableTimesContainer}
                refreshFunction={this.RefreshBookings.bind(this)}
                containerEndDateList={this.state.nextThirtyDays}
              />
            </div>
          )
        }
      }
      </Translation>
    );
  }
}

export const ReservationCalendarContainer = withRouter(ReservationCalendar)
export default ReservationCalendarContainer
