import React from 'react';
import './LanguageSelector.css';
import { useTranslation } from "react-i18next";

const SelectLanguageButton = (props: any) => {
    const { i18n } = useTranslation();
    return (
      <h3 className="link-padding" onClick={() => i18n.changeLanguage(props.languageCode)}>{props.languageText}</h3>
    );
  }


class LanguageSelector extends React.Component<{}, {}> {

    public render() {
        return (
            <div className="selector-container">
                <SelectLanguageButton languageText="Fi" languageCode="fi"/>
                <SelectLanguageButton languageText="En" languageCode="en"/>
            </div>
        )
    }
}

export default LanguageSelector;