import React from 'react';
import {Link} from 'react-router-dom';
import './SidebarLink.css';
import { Translation } from 'react-i18next';

class SidebarLink extends React.Component<{defaultPath: string, destination: string, linkText: string, clickFunction?: any, showTo: string}, {}> {

    public static defaultProps = {
        showTo: "admin",
        defaultPath: "/admin/"
    };

    public render() {
        return (
            <Translation>
                {
                    t => {
                        return (
                            <div className="link-margins">
                                <Link className="link-style" to={this.props.defaultPath + this.props.destination} onClick={this.props.clickFunction || null}>{t(this.props.linkText)}</Link>
                            </div>
                        )
                    }
                }
            </Translation>
        );
    }
}

export default SidebarLink;
