import * as React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import './App.css';
import LoginContainer from './Containers/Login/LoginContainer';
import AdminContainer from './Containers/Admin/AdminContainer';
import MobileQrReaderContainer from './Containers/MobileQrReader/MobileQrReaderContainer';

class App extends React.Component<{}, {}> {

  public Login() {
    return <LoginContainer />
  }

  public Admin() {
    return <AdminContainer />
  }

  public MobileQrReader() {
    return <MobileQrReaderContainer />
  }

  public render() {
    return (
      <Router>
        <Route exact path="/" component={this.Login} />
        <Route path="/admin" component={this.Admin} />
        <Route exact path="/qr-reader" component={this.MobileQrReader} />
      </Router>
    )
  }
}

export default App;
