import React from 'react';
import './Booking.css';
import { BookingData } from './IBooking';
import { Translation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import axios from 'axios';

const path = process.env.REACT_APP_RESERVATIONS || "";

class Booking extends React.Component<{booking: BookingData, refreshFunction?: any}, {}> {

    private DeleteBooking(confirmMessage: string) {
        if (window.confirm(confirmMessage)) {
            axios.delete(path + "/" + this.props.booking.id).then(msg => {
                if (this.props.refreshFunction !== null) {
                    this.props.refreshFunction();
                }
            })
        }
    }

    public render() {
        return (
            <Translation>
                {
                    t => {
                        return (
                            <div className="booking-card">
                                <p>{t("LASTNAME")}: {this.props.booking.lastName}</p>
                                <p>{t("PHONE_NUMBER")}: {this.props.booking.phoneNumber}</p>
                                <p style={{margin: "6px 0"}}>{t("TIME")}:</p>
                                <p style={{margin: "6px 0"}}>{moment(this.props.booking.startTime).format("HH:mm DD.MM.YYYY")}</p>
                                <p style={{margin: "6px 0"}}>-</p>
                                <p style={{margin: "6px 0"}}>{moment(this.props.booking.endTime).format("HH:mm DD.MM.YYYY")}</p>
                                <p>{t("EQUIPMENT_NAME")}: {this.props.booking.equipmentName}</p>
                                <p>{t("EQUIPMENT_TYPE")}: {this.props.booking.equipmentType}</p>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => this.DeleteBooking(t("CONFIRM_DELETE") + t("RESERVATION"))}
                                >
                                    {t("DELETE")}
                                </Button>
                            </div>
                        )
                    }
                }
            </Translation>
        );
    }
}

export default Booking;
