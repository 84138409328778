import React from 'react';
import './UserGuidesContainer.css';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from 'react-router';
import { Translation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import UserGuidesStates from './IUserGuidesStates';

type HomeProps = RouteComponentProps

const path = process.env.REACT_APP_GUIDES || "";

class UserGuides extends React.Component<HomeProps, UserGuidesStates> {

    pdfInput: any = "";
    imageInput: any = "";

    constructor(props: HomeProps) {
        super(props);
        this.state = {
            guides: [],
            selectedPdf: "",
            selectedImage: "",
            selectedHeader: ""
        };
        this.pdfInput = React.createRef();
        this.imageInput = React.createRef();
    }

    public componentDidMount() {
        this.GetGuides();
    }

    private GetGuides() {
        axios.get(path)
            .then(res => {
                this.setState({guides: res.data.userGuides});
        })
            .catch(res => {
                console.log(res);
        });
    }

    private AddGuide() {
        this.ResetInputs();
        if (this.state.selectedPdf !== "" && this.state.selectedImage !== "" && this.state.selectedHeader !== "") { 
            let data = new FormData();

            data.append("Title", this.state.selectedHeader);
            data.append("Pdf", this.state.selectedPdf);
            data.append("Icon", this.state.selectedImage);

            axios.post(path, data).then(msg => {
                this.GetGuides();
                this.ResetInputs();
            });
        }
    }

    private DeleteGuide(data: any, confirmMessage: string) {
        if (window.confirm(confirmMessage + data.title)) {
            axios.delete(path + "/" + data.id).then(msg => {
                this.GetGuides();
            })
        }
    }

    private ResetInputs() {
        this.setState({selectedPdf: "", selectedImage: "", selectedHeader: ""});
        this.pdfInput.value = "";
        this.imageInput.value= "";
    }

    private SetPdf(event: any) {
        this.setState({selectedPdf: event.target.files[0]})
    }

    private SetImage(event: any) {
        this.setState({selectedImage: event.target.files[0]})
    }

    private SetHeader(event: any) {
        this.setState({selectedHeader: event.target.value})
    }

    public render() {
        return (
        <div className="list-container">
            <Translation>
                {
                    (t) => {
                        return (
                            <div>
                                <div>
                                    <div>
                                        <p style={{marginBottom: "3px"}}>Pdf</p>
                                        <input
                                            id="pdfInput"
                                            type="file"
                                            accept=".pdf"
                                            name="pdf"
                                            ref={ref => this.pdfInput = ref}
                                            onChange={(event) => this.SetPdf(event)}
                                        />
                                    </div>
                                    <div>
                                        <p style={{marginBottom: "3px"}}>image</p>
                                        <input 
                                            id="imageInput"
                                            type="file"
                                            accept=".png"
                                            name="image"
                                            ref={ref => this.imageInput = ref}
                                            onChange={(event) => this.SetImage(event)}
                                        />
                                    </div>
                                    <div style={{marginTop: "20px", marginBottom: "20px"}}>
                                        <TextField 
                                            type="text"
                                            placeholder={t("HEADER")}
                                            label={t("HEADER")}
                                            name="header"
                                            value={this.state.selectedHeader}
                                            onChange={(event => this.SetHeader(event))}
                                        />
                                    </div>
                                    <Button variant="contained" onClick={() => this.AddGuide()}>
                                        {t("SAVE")}
                                    </Button>
                                </div>
                                <div className="pdf-menu">
                                {
                                    this.state.guides.map((guide, index) => {
                                    return (
                                        <div key={index} className="guide-block">
                                            <div style={{textAlign: "center"}}>
                                                <img src={"data:image/png;base64, " + guide.icon} alt="" style={{maxWidth: "100%", maxHeight: "300px"}} />
                                                <h3>{ guide.title }</h3>
                                            </div>
                                            <div className="buttons">
                                                <Button variant="contained" onClick={() => this.DeleteGuide(guide, t("CONFIRM_DELETE"))}>
                                                    { t("DELETE") }
                                                </Button>
                                            </div>
                                        </div>
                                    )
                                    })
                                }
                                </div>
                            </div>
                        );
                    }
                }
            </Translation>
        </div>
        );
    }
}

export const UserGuidesContainer = withRouter(UserGuides)
export default UserGuidesContainer;