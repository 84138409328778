import React from 'react';
import './UserListContainer.css';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from 'react-router';
import MaterialTable from 'material-table';
import { Translation } from 'react-i18next';

type HomeProps = RouteComponentProps

const path = process.env.REACT_APP_USERS || "";

class UserList extends React.Component<HomeProps, {users: any[]}> {

    constructor(props: HomeProps) {
        super(props);
        this.state = {
            users: []
        };
    }

    public componentDidMount() {
        this.GetUsers();
    }

    private GetUsers() {

        axios.get(path)
            .then(res => {
                this.setState({users: res.data.users});
        })
            .catch(res => {
                console.log(res);
        });
        
    }

    private AddUser(data: any): Promise<any> {
        return axios.post(path, data);
    }

    private UpdateUser(newData: any, oldData: any): Promise<any> {
        let updatedData = {username: newData.username, email: newData.email, role: newData.role};
        return axios.put(path + "/" + newData.id, updatedData);
    }

    private DeleteUser(data: any): Promise<any> {
        return axios.delete(path + "/" + data.id);
    }

    public render() {
        return (
        <div className="list-container">
            <Translation>
                {
                    (t) => {
                        return (
                            <MaterialTable
                                columns={[
                                    { title: "Id", field: "id", editable: "never", hidden: true },
                                    { title: t("PASSWORD"), field: "password", editable: "onAdd"},
                                    { title: t("ROLE"), field: "role", lookup: {"Administrator": t("ADMINISTRATOR")} },
                                    { title: t("EMAIL"), field: "email"},
                                    { title: t("USERNAME"), field: "username"}
                                ]}
                                editable={{
                                    isEditable: rowData => true,
                                    isDeletable: rowData => true,
                                    onRowAdd: newData =>
                                        this.AddUser(newData).then(() => {
                                            this.GetUsers();
                                        })
                                        .catch((error) => {
                                            alert(t("ERROR_CREATE_USER"));
                                        }),
                                    onRowUpdate: (newData, oldData) => 
                                        this.UpdateUser(newData, oldData).then(() => {
                                            this.GetUsers();
                                        }),
                                    onRowDelete: oldData =>
                                        this.DeleteUser(oldData).then(() => {
                                            this.GetUsers();
                                        })
                                }}
                                localization={{
                                    body: {
                                        addTooltip: t("ADD"),
                                        deleteTooltip: t("DELETE"),
                                        editTooltip: t("EDIT"),
                                        filterRow: {
                                            filterTooltip: t("FILTER"),
                                        },
                                        editRow: {
                                            deleteText: t("CONFIRM_DELETE_ROW"),
                                            cancelTooltip: t("CANCEL"),
                                            saveTooltip: t("SAVE")
                                        }
                                    },
                                    header: {
                                        actions: t("ACTIONS")
                                    },
                                    toolbar: {
                                        searchTooltip: t("SEARCH"),
                                        searchPlaceholder: t("SEARCH")
                                    }
                                }}
                                options={{
                                    pageSize: 15
                                }}
                                data={this.state.users}
                                title={t("USERS")}
                                style={{width: "100%"}}
                            />
                        );
                    }
                }
            </Translation>
        </div>
        );
    }
}

export const UserListContainer = withRouter(UserList)
export default UserListContainer;