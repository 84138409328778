import React from 'react';
import './MobileQrReaderContainer.css';
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from 'react-router';
import { Translation } from "react-i18next";
import QrReader from 'react-qr-reader';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';

type HomeProps = RouteComponentProps

const path = process.env.REACT_APP_VISITS || "";
const heartPath = process.env.REACT_APP_HEARTBEAT || "";

class MobileQrReader extends React.Component<HomeProps, {scanning: boolean, sending: boolean}> {

  constructor(props: HomeProps) {
    
    super(props);
    this.state = {
      scanning: false,
      sending: false
    };
  }

  public componentDidMount() {
    this.CheckLogin();
  }

  private Logout() {
    axios.post(process.env.REACT_APP_USERS + "/logout")
    .then(res => {
      this.props.history.push("/");
      })
    .catch(res => {
      this.props.history.push("/");
    });
  }

  private HandleError(error: any) {
    alert(error);
  }

  public CheckLogin() {
    axios.get(heartPath).catch(err => {
      this.props.history.push("/");
    })
  }

  private SetReaderScanning(scanState: boolean) {
    this.CheckLogin();
    this.setState({scanning: scanState});
  }

  public render() {
    return (
      <Translation> 
      {
        t => {
          return (
            <div className="centered-text-header-scanner">
              <h1>{t("VISIT_CODE_READER")}</h1>
              {this.state.scanning === true &&
              <div style={{width: '100%'}}>
                <QrReader
                  delay={300}
                  onError={this.HandleError}
                  onScan={data => {
                    let customerId = data || "";
                    if (data != null && customerId !== "" && this.state.sending === false) {
                      this.setState({sending: true});
                      let testData = customerId.replace(/"/g, '');
                      let visitData = {customerCardId: testData, wasteFacilityId: 0};
                      axios.post(path, visitData).then((res: any) => {
                        this.setState({sending: false, scanning: false});
                        let message = t("SCAN_COMPLETED");
                        if (res.data.freeVisit === true) {
                          message += "\n" + t("FREE_VISIT");
                        }
                        setTimeout(() => {alert(message);}, 0);
                      })
                      .catch(error => {
                        this.setState({sending: false, scanning: false});
                        console.log(error);
                        setTimeout(() => {alert("ERROR: " + error);}, 0);
                      })
                    }
                  }}
                  style={{width: '100%'}}
                />
                {this.state.sending === true &&
                <div>
                  <CircularProgress />
                  <h4 style={{margin: "2px"}}>{t("CHECKING_CODE")}</h4>
                </div>
                }
                {this.state.sending === false &&
                <Button variant="contained" onClick={this.SetReaderScanning.bind(this, false)}>
                  {t("CANCEL")}
                </Button>
                }
              </div>
              }
              {this.state.scanning === false &&
              <>
                <div className="bottom-page">
                  <Button variant="contained" onClick={this.SetReaderScanning.bind(this, true)}>
                    {t("START_SCAN")}
                  </Button>
                </div>
                <div className="corner-logout">
                  <Button variant="contained" onClick={this.Logout.bind(this)}>
                    {t("LOGOUT")}
                  </Button>
                </div>
              </>
              }
            </div>
          )
        }
      }
      </Translation>
    );
  }
}

export const MobileQrReaderContainer = withRouter(MobileQrReader)
export default MobileQrReaderContainer
