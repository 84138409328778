import React from 'react';
import './WasteItemsContainer.css';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from 'react-router';
import MaterialTable from 'material-table';
import { Translation } from 'react-i18next';
import { getLocalization } from '../../Utils/Helpers/localizationUtils';
import WasteItemsState, { IWasteItem } from './IWasteItemsState';

type HomeProps = RouteComponentProps

const path = process.env.REACT_APP_WASTEITEMS || "";
const typePath = process.env.REACT_APP_WASTETYPES || "";

class WasteItems extends React.Component<HomeProps, WasteItemsState> {

    constructor(props: HomeProps) {
        super(props);
        this.state = {
            wasteItemsRaw: [],
            wasteItems: [],
            wasteTypes: {}
        };
    }

    public componentDidMount() {
        this.GetWasteTypes();
        this.GetWasteItems();
    }

    private GetWasteTypes() {
        axios.get(typePath)
            .then(res => {
                let list: any[] = res.data.wasteTypes;
                let orderedList = list.sort((a, b) => {
                  let textA = getLocalization(a.names, "fi").toUpperCase();
                  let textB = getLocalization(b.names, "fi").toUpperCase();
                  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                let wasteTypes: any = {};
                for (let i = 0; i < orderedList.length; i++) {
                    let current = orderedList[i];
                    wasteTypes[current.id] = getLocalization(current.names, "fi") + " / " + getLocalization(current.names, "en");
                }
                this.setState({wasteTypes: wasteTypes});
            })
            .catch(error => {
                console.log(error);
            });
    }

    private GetWasteItems() {

        axios.get(path)
            .then(res => {
                this.setState({wasteItemsRaw: res.data.wasteItems});
                let tempContainer = [];
                for (let i = 0; i < res.data.wasteItems.length; i++) {
                    let current = res.data.wasteItems[i];
                    let fLabel = getLocalization(current.names, "fi");
                    let eLabel = getLocalization(current.names, "en");
                    let typeId = current.wasteTypeId;
                    let fixed = { id: current.id, wasteTypeId: typeId, finnishLabel: fLabel, englishLabel: eLabel };
                    tempContainer.push(fixed);
                }
                this.setState({wasteItems: tempContainer});
        })
            .catch(res => {
                console.log(res);
        });
        
    }

    private AddWasteItem(data: any): Promise<any> {
        const createdData: IWasteItem = {wasteTypeId: Number(data.wasteTypeId), finnishLabel: data.finnishLabel, englishLabel: data.englishLabel };
        return axios.post(path, createdData);
    }

    private UpdateWasteItem(newData: any, oldData: any): Promise<any> {
        const updatedData: IWasteItem = {wasteTypeId: Number(newData.wasteTypeId), finnishLabel: newData.finnishLabel, englishLabel: newData.englishLabel };
        return axios.put(path + "/" + newData.id, updatedData);
    }

    private DeleteWasteItem(data: any): Promise<any> {
        return axios.delete(path + "/" + data.id);
    }

    public render() {
        return (
        <div className="list-container">
            <Translation>
                {
                    (t, { i18n }) => {
                        return (
                            <MaterialTable
                                columns={[
                                    { title: "Id", field: "id", editable: "never", hidden: true },
                                    { title: t("FINNISH_LABEL"), field: "finnishLabel" },
                                    { title: t("ENGLISH_LABEL"), field: "englishLabel" },
                                    { title: t("WASTE_TYPE"), field: "wasteTypeId", lookup: this.state.wasteTypes, emptyValue: () => <div>{t("TYPE_DELETED")}</div>}
                                ]}
                                editable={{
                                    isEditable: rowData => true,
                                    isDeletable: rowData => true,
                                    onRowAdd: newData =>
                                        this.AddWasteItem(newData).then(() => {
                                            this.GetWasteItems();
                                        })
                                        .catch((err) => {
                                            console.log(err);
                                        }),
                                    onRowUpdate: (newData, oldData) => 
                                        this.UpdateWasteItem(newData, oldData).then(() => {
                                            this.GetWasteItems();
                                        }),
                                    onRowDelete: oldData =>
                                        this.DeleteWasteItem(oldData).then(() => {
                                            this.GetWasteItems();
                                        })
                                }}
                                localization={{
                                    body: {
                                        addTooltip: t("ADD"),
                                        deleteTooltip: t("DELETE"),
                                        editTooltip: t("EDIT"),
                                        filterRow: {
                                            filterTooltip: t("FILTER"),
                                        },
                                        editRow: {
                                            deleteText: t("CONFIRM_DELETE_ROW"),
                                            cancelTooltip: t("CANCEL"),
                                            saveTooltip: t("SAVE")
                                        }
                                    },
                                    header: {
                                        actions: t("ACTIONS")
                                    },
                                    toolbar: {
                                        searchTooltip: t("SEARCH"),
                                        searchPlaceholder: t("SEARCH")
                                    }
                                }}
                                options={{
                                    pageSize: 15
                                }}
                                data={this.state.wasteItems}
                                title={t("WASTE_ITEMS")}
                                style={{width: "100%"}}
                            />
                        );
                    }
                }
            </Translation>
        </div>
        );
    }
}

export const WasteItemsContainer = withRouter(WasteItems)
export default WasteItemsContainer;