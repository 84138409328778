import React from 'react';
import './ReviewListContainer.css';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from 'react-router';
import MaterialTable from 'material-table';
import { Translation } from 'react-i18next';
import moment from 'moment';

type HomeProps = RouteComponentProps

const path = process.env.REACT_APP_REVIEWS || "";

class ReviewList extends React.Component<HomeProps, {reviews: any[]}> {

    constructor(props: HomeProps) {
        super(props);
        this.state = {
            reviews: []
        };
    }

    public componentDidMount() {
        this.GetReviews();
    }

    private GetReviews() {

        axios.get(path)
            .then(res => {
                this.setState({reviews: res.data.visits});
        })
            .catch(res => {
                console.log(res);
        });
        
    }

    public render() {
        return (
        <div className="list-container">
            <Translation>
                {
                    (t) => {
                        return (
                            <MaterialTable
                                columns={[
                                    { title: "Id", field: "id", editable: "never", hidden: true },
                                    { title: t("CUSTOMER_NUMBER"), field: "customerNumber" },
                                    { title: t("LASTNAME"), field: "lastName" },
                                    { title: t("SCORE"), field: "rating" },
                                    { title: t("REVIEW_DATE"), field: "created", render: rowData => <>{moment(rowData.created).format("YYYY.MM.DD kk.mm")}</>}
                                ]}
                                localization={{
                                    body: {
                                        addTooltip: t("ADD"),
                                        deleteTooltip: t("DELETE"),
                                        editTooltip: t("EDIT"),
                                        filterRow: {
                                            filterTooltip: t("FILTER"),
                                        },
                                        editRow: {
                                            deleteText: t("CONFIRM_DELETE_ROW"),
                                            cancelTooltip: t("CANCEL"),
                                            saveTooltip: t("SAVE")
                                        }
                                    },
                                    header: {
                                        actions: t("ACTIONS")
                                    },
                                    toolbar: {
                                        searchTooltip: t("SEARCH"),
                                        searchPlaceholder: t("SEARCH")
                                    }
                                }}
                                options={{
                                    pageSize: 15
                                }}
                                data={this.state.reviews}
                                title={t("REVIEWS")}
                                style={{width: "100%"}}
                            />
                        );
                    }
                }
            </Translation>
        </div>
        );
    }
}

export const ReviewListContainer = withRouter(ReviewList)
export default ReviewListContainer;