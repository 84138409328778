import React from 'react';
import './CustomerListContainer.css';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from 'react-router';
import MaterialTable from 'material-table';
import { Translation } from 'react-i18next';

type HomeProps = RouteComponentProps

const path = process.env.REACT_APP_CUSTOMERS || "";

class CustomerList extends React.Component<HomeProps, {customers: any[]}> {

    constructor(props: HomeProps) {
        super(props);
        this.state = {
            customers: []
        };
    }

    public componentDidMount() {
        this.GetUsers();
    }

    private GetUsers() {

        axios.get(path + "?count=1000000")
            .then(res => {
                let basicCustomers: any[] = res.data.customers;
                let filteredCustomers = basicCustomers.filter(customer => customer.deleted === false);
                this.setState({customers: filteredCustomers});
        })
            .catch(res => {
                console.log(res);
        });
        
    }

    private AddUser(data: any): Promise<any> {
        return axios.post(path, data);
    }

    private UpdateUser(newData: any, oldData: any): Promise<any> {
        const updatedData = {lastName: newData.lastName, email: newData.email, customerType: newData.customerType, address: newData.address, phoneNumber: newData.phoneNumber, emailVerified: newData.emailVerified, deleted: newData.deleted };
        return axios.put(path + "/" + newData.customerNumber, updatedData);
    }

    private DeleteUser(data: any): Promise<any> {
        return axios.delete(path + "/" + data.customerNumber);
    }

    public render() {
        return (
        <div className="list-container">
            <Translation>
                {
                    (t) => {
                        return (
                            <MaterialTable
                                columns={[
                                    { title: "Id", field: "customerNumber", editable: "never", hidden: true },
                                    { title: t("VERIFIED"), field: "emailVerified", editable: "onUpdate", lookup: { true: t("TRUE"), false: t("FALSE") }},
                                    { title: t("CUSTOMER_NUMBER"), field: "customerNumber", editable: "never"},
                                    { title: t("CUSTOMER_TYPE"), field: "customerType", editable: "never", initialEditValue: 1, lookup: {0: t("DIRECT"), 1: t("INDIRECT")} },
                                    { title: t("LASTNAME"), field: "lastName"},
                                    { title: t("ADDRESS"), field: "address"},
                                    { title: t("EMAIL"), field: "email"},
                                    { title: t("PHONE_NUMBER"), field: "phoneNumber"}
                                ]}
                                editable={{
                                    isEditable: rowData => rowData.customerType === 1,
                                    isDeletable: rowData => rowData.customerType === 1,
                                    onRowAdd: newData =>
                                        this.AddUser(newData).then(() => {
                                            this.GetUsers();
                                        })
                                        .catch((err) => {
                                            console.log(err);
                                        }),
                                    onRowUpdate: (newData, oldData) => 
                                        this.UpdateUser(newData, oldData).then(() => {
                                            this.GetUsers();
                                        }),
                                    onRowDelete: oldData =>
                                        this.DeleteUser(oldData).then(() => {
                                            this.GetUsers();
                                        })
                                }}
                                localization={{
                                    body: {
                                        addTooltip: t("ADD"),
                                        deleteTooltip: t("DELETE"),
                                        editTooltip: t("EDIT"),
                                        filterRow: {
                                            filterTooltip: t("FILTER"),
                                        },
                                        editRow: {
                                            deleteText: t("CONFIRM_DELETE_ROW"),
                                            cancelTooltip: t("CANCEL"),
                                            saveTooltip: t("SAVE")
                                        }
                                    },
                                    header: {
                                        actions: t("ACTIONS")
                                    },
                                    toolbar: {
                                        searchTooltip: t("SEARCH"),
                                        searchPlaceholder: t("SEARCH")
                                    }
                                }}
                                options={{
                                    pageSize: 15
                                }}
                                data={this.state.customers}
                                title={t("CUSTOMERS")}
                                style={{width: "100%"}}
                            />
                        );
                    }
                }
            </Translation>
        </div>
        );
    }
}

export const CustomerListContainer = withRouter(CustomerList)
export default CustomerListContainer;