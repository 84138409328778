import React from 'react';
import './WasteTypesContainer.css';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from 'react-router';
import MaterialTable from 'material-table';
import { Translation } from 'react-i18next';
import { getLocalization } from '../../Utils/Helpers/localizationUtils';

type HomeProps = RouteComponentProps

const path = process.env.REACT_APP_WASTETYPES || "";

class WasteTypes extends React.Component<HomeProps, {wasteTypesRaw: any[], wasteTypes: any[]}> {

    constructor(props: HomeProps) {
        super(props);
        this.state = {
            wasteTypesRaw: [],
            wasteTypes: []
        };
    }

    public componentDidMount() {
        this.GetUsers();
    }

    private GetUsers() {

        axios.get(path)
            .then(res => {
                this.setState({wasteTypesRaw: res.data.wasteTypes});
                let tempContainer = [];
                for (let i = 0; i < res.data.wasteTypes.length; i++) {
                    let current = res.data.wasteTypes[i];
                    let fLabel = getLocalization(current.names, "fi");
                    let eLabel = getLocalization(current.names, "en");
                    let fixed = { id: current.id, finnishLabel: fLabel, englishLabel: eLabel, integrated: current.isIntegrationData };
                    tempContainer.push(fixed);
                }
                this.setState({wasteTypes: tempContainer});
        })
            .catch(res => {
                console.log(res);
        });
        
    }

    private AddUser(data: any): Promise<any> {
        return axios.post(path, data);
    }

    private UpdateUser(newData: any, oldData: any): Promise<any> {
        const updatedData = {finnishLabel: newData.finnishLabel, englishLabel: newData.englishLabel };
        return axios.put(path + "/" + newData.id, updatedData);
    }

    private DeleteUser(data: any): Promise<any> {
        return axios.delete(path + "/" + data.id);
    }

    public render() {
        return (
        <div className="list-container">
            <Translation>
                {
                    (t) => {
                        return (
                            <MaterialTable
                                columns={[
                                    { title: "Id", field: "id", editable: "never", hidden: true },
                                    { title: "Integrated", field: "integrated", editable: "never", hidden: true },
                                    { title: t("FINNISH_LABEL"), field: "finnishLabel" },
                                    { title: t("ENGLISH_LABEL"), field: "englishLabel" }
                                ]}
                                editable={{
                                    isEditable: rowData => rowData.integrated === false,
                                    isDeletable: rowData => rowData.integrated === false,
                                    onRowAdd: newData =>
                                        this.AddUser(newData).then(() => {
                                            this.GetUsers();
                                        })
                                        .catch((err) => {
                                            console.log(err);
                                        }),
                                    onRowUpdate: (newData, oldData) => 
                                        this.UpdateUser(newData, oldData).then(() => {
                                            this.GetUsers();
                                        }),
                                    onRowDelete: oldData =>
                                        this.DeleteUser(oldData).then(() => {
                                            this.GetUsers();
                                        })
                                }}
                                localization={{
                                    body: {
                                        addTooltip: t("ADD"),
                                        deleteTooltip: t("DELETE"),
                                        editTooltip: t("EDIT"),
                                        filterRow: {
                                            filterTooltip: t("FILTER"),
                                        },
                                        editRow: {
                                            deleteText: t("CONFIRM_DELETE_ROW"),
                                            cancelTooltip: t("CANCEL"),
                                            saveTooltip: t("SAVE")
                                        }
                                    },
                                    header: {
                                        actions: t("ACTIONS")
                                    },
                                    toolbar: {
                                        searchTooltip: t("SEARCH"),
                                        searchPlaceholder: t("SEARCH")
                                    }
                                }}
                                options={{
                                    pageSize: 15
                                }}
                                data={this.state.wasteTypes}
                                title={t("WASTE_TYPES")}
                                style={{width: "100%"}}
                            />
                        );
                    }
                }
            </Translation>
        </div>
        );
    }
}

export const WasteTypesContainer = withRouter(WasteTypes)
export default WasteTypesContainer;