import React from 'react';
import './WasteFacilitiesContainer.css';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from 'react-router';
import MaterialTable from 'material-table';
import { Translation } from 'react-i18next';

type HomeProps = RouteComponentProps

const path = process.env.REACT_APP_FACILITIES || "";
const editPath = "/admin/facilities/edit";

class WasteFacilities extends React.Component<HomeProps, {facilities: any[]}> {

    constructor(props: HomeProps) {
        super(props);
        this.state = {
            facilities: []
        };
    }

    public componentDidMount() {
        this.GetUsers();
    }

    private GetUsers() {
        axios.get(path)
            .then(res => {
                this.setState({facilities: res.data.wasteFacilities});
        })
            .catch(res => {
                console.log(res);
        });
    }

    private NewUser() {
        this.props.history.push(editPath);
    }

    private EditUser(rowData: any) {
        this.props.history.push(editPath + "/" + rowData.id);
    }

    private DeleteUser(rowData: any, confirmMessage: string) {
        let result = window.confirm(confirmMessage + rowData.name);
        if (result === true)
        {
            axios.delete(path + "/" + rowData.id).then((data) => {
                this.GetUsers();
            })
        }
    }

    public render() {
        return (
        <div className="list-container">
            <Translation>
                {
                    (t, { i18n }) => {
                        return (
                            <MaterialTable
                                columns={[
                                    { title: "Id", field: "id", editable: "never", hidden: true },
                                    { title: t("FACILITY_TYPE"), field: "wasteFacilityTypeId", lookup: {1: t("WASTE_STATION"), 2: t("ECOPOINT"), 3: t("WASTE_FACILITY")} },
                                    { title: t("ADDRESS"), field: "address"},
                                    { title: t("NAME"), field: "name"}
                                ]}
                                localization={{
                                    body: {
                                        addTooltip: t("ADD"),
                                        deleteTooltip: t("DELETE"),
                                        editTooltip: t("EDIT"),
                                        filterRow: {
                                            filterTooltip: t("FILTER"),
                                        },
                                        editRow: {
                                            deleteText: t("CONFIRM_DELETE_ROW"),
                                            cancelTooltip: t("CANCEL"),
                                            saveTooltip: t("SAVE")
                                        }
                                    },
                                    header: {
                                        actions: t("ACTIONS")
                                    },
                                    toolbar: {
                                        searchTooltip: t("SEARCH"),
                                        searchPlaceholder: t("SEARCH")
                                    }
                                }}
                                actions={[
                                    {
                                        icon: "add",
                                        tooltip: t("ADD"),
                                        isFreeAction: true,
                                        onClick: () => this.NewUser()
                                    },
                                    {
                                        icon: "edit",
                                        tooltip: t("EDIT"),
                                        onClick: (event, rowData) => this.EditUser(rowData)
                                    },
                                    {
                                        icon: "delete",
                                        tooltip: t("DELETE"),
                                        onClick: (event, rowData) => this.DeleteUser(rowData, t("CONFIRM_DELETE"))
                                    }
                                ]}
                                options={{
                                    pageSize: 15
                                }}
                                data={this.state.facilities}
                                title={t("WASTEFACILITIES")}
                                style={{width: "100%"}}
                            />
                        );
                    }
                }
            </Translation>
        </div>
        );
    }
}

export const WasteFacilitiesContainer = withRouter(WasteFacilities)
export default WasteFacilitiesContainer;