import React from 'react';
import {Route, withRouter, RouteComponentProps} from 'react-router-dom';
import './AdminContainer.css';
import SidebarContainer from '../Sidebar/SidebarContainer';
import UsersListContainer from '../UserList/UserListContainer';
import CustomerListContainer from '../CustomerList/CustomerListContainer';
import WasteFacilitiesContainer from '../WasteFacilities/WasteFacilitiesContainer';
import WasteFacilityEditContainer from '../WasteFacilityEdit/WasteFacilityEditContainer';
import WasteTypesContainer from '../WasteTypes/WasteTypesContainer';
import WasteItemsContainer from '../WasteItems/WasteItemsContainer';
import FrontPageContainer from '../FrontPage/FrontPageContainer';
import MobileQrReaderContainer from '../MobileQrReader/MobileQrReaderContainer';
import ReservationCalendar from '../ReservationCalendar/ReservationCalendarContainer';
import ReviewListContainer from '../ReviewList/ReviewListContainer';
import UserGuidesContainer from '../UserGuides/UserGuidesContainer';
import axios from 'axios';

type HomeProps = RouteComponentProps

const path = process.env.REACT_APP_HEARTBEAT || "";

class Admin extends React.Component<HomeProps, {showSidebar: boolean}> {

  private readonly usersPath = "/admin/users";
  private readonly customersPath = "/admin/customers";
  private readonly facilitiesPath = "/admin/facilities";
  private readonly editFacilityPath = this.facilitiesPath + "/edit";
  private readonly wasteTypesPath = "/admin/waste-types";
  private readonly wasteItemsPath = "/admin/waste-items";
  private readonly mobileQrReaderPath = "/admin/qr-reader";
  private readonly reservationCalendarPath = "/admin/reservations";
  private readonly reviewsPath = "/admin/reviews";
  private readonly userGuidesPath = "/admin/user-guides";
  private readonly basePath = "/admin";


  constructor(props: HomeProps) {
    super(props);
    this.state = {
      showSidebar: true
    }
  }

  public componentDidMount() {
    this.CheckLogin();
  }

  public CheckLogin() {
    axios.get(path).catch(err => {
      this.props.history.push("/");
    })
  }

  private RenderSidebar() {
    return this.state.showSidebar ? <SidebarContainer /> : null; 
  }

  public FrontPage() {
    return <FrontPageContainer />
  }

  public Users() {
    return <UsersListContainer />;
  }

  public Customers() {
    return <CustomerListContainer />
  }

  public Facilities() {
    return <WasteFacilitiesContainer />
  }

  public FacilityEdit() {
    return <WasteFacilityEditContainer />
  }

  public WasteTypes() {
    return <WasteTypesContainer />
  }

  public WasteItems() {
    return <WasteItemsContainer />
  }

  public MobileQrReader() {
    return <MobileQrReaderContainer />
  }

  public ReservationCalendar() {
    return <ReservationCalendar />
  }

  public ReviewList() {
    return <ReviewListContainer />
  }

  public UserGuides() {
    return <UserGuidesContainer />
  }

  public render() {
    return (
      <div className="page-divide">
        {this.RenderSidebar()}
        <div className="universal-padding">
          <Route exact path={this.usersPath} component={this.Users} />
          <Route exact path={this.customersPath} component={this.Customers} />
          <Route exact path={this.facilitiesPath} component={this.Facilities} />
          <Route exact path={this.editFacilityPath} component={this.FacilityEdit}/>
          <Route exact path={this.editFacilityPath + "/:id"} component={this.FacilityEdit} />
          <Route exact path={this.wasteTypesPath} component={this.WasteTypes} />
          <Route exact path={this.wasteItemsPath} component={this.WasteItems} />
          <Route exact path={this.mobileQrReaderPath} component={this.MobileQrReader} />
          <Route exact path={this.reservationCalendarPath} component={this.ReservationCalendar} />
          <Route exact path={this.reviewsPath} component={this.ReviewList} />
          <Route exact path={this.userGuidesPath} component={this.UserGuides} />
          <Route exact path={this.basePath} component={this.FrontPage} />
        </div>
      </div>
    );
  }
}

export const AdminContainer = withRouter(Admin)
export default AdminContainer;
