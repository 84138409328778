import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import './LoginContainer.css';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from 'react-router';
import ILoginState from './ILoginStates';
import { Translation } from "react-i18next";
import LanguageSelector from '../../Components/LanguageSelector/LanguageSelector';
import {isMobile} from 'react-device-detect';

type HomeProps = RouteComponentProps

const useStyles = makeStyles({
  root: {margin: "10px"}
});

const MarginButton = (props: any) => {
  const classes = useStyles();
  return (
    <Button className={classes.root} variant="contained" color="primary" onClick={props.Function}>{props.t("LOGIN")}</Button>
  );
}

class Login extends React.Component<HomeProps, ILoginState> {

  constructor(props: HomeProps) {
    
    super(props);
    this.state = {
      username: "",
      password: "",
      loginError: false
    };
  }

  public Login() {

    axios.post(process.env.REACT_APP_USERS + "/login", JSON.stringify({email: this.state.username, password: this.state.password}))
      .then(res => {
        console.log(res);
        if (res.status === 200) {
          if (isMobile) {
            this.props.history.push("/qr-reader");
          }
          else {
            this.props.history.push("/admin");
          }

        }
      })
      .catch(res => {
        this.setState({loginError: true});
        console.log(res);
      })
  }

  public render() {
    return (
      <Translation> 
      {
        t => {
          return (
            <>
              <div className="centered-container">
                  <TextField
                    label={t("EMAIL")}
                    onChange={(value) => this.setState({username: value.target.value})}
                    type="email"
                  />
                  <TextField
                    label={t("PASSWORD")}
                    onChange={(value) => this.setState({password: value.target.value})}
                    type="password"
                  />
                  { this.state.loginError === true &&
                    <span style={{color: "red"}}>
                      {t("ERROR_USER_PASSWORD")}
                    </span>
                  }
                  <MarginButton t={t} Function={this.Login.bind(this)}/>
              </div>
              <div className="language-selector-position-container">
                <div className="language-selector-position">
                  <LanguageSelector />
                </div>
              </div>
            </>
          )
        }
      }
      </Translation>
    );
  }
}

export const LoginContainer = withRouter(Login)
export default LoginContainer
