import React from 'react';
import './FrontPageContainer.css';
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from 'react-router';
import { Translation } from "react-i18next";
import puhas_flower from '../../Images/puhas_flower.png'
import puhas_logo from '../../Images/puhas_logo.png'

type HomeProps = RouteComponentProps

class FrontPage extends React.Component<HomeProps, {}> {

  constructor(props: HomeProps) {
    
    super(props);
    this.state = {
    };
  }

  public render() {
    return (
      <Translation> 
      {
        t => {
          return (
            <div className="centered-text-header">
              <h1>{t("FRONTPAGE_HEADER")}</h1>
              <img src={puhas_flower} alt=""></img>
              <img src={puhas_logo} alt=""></img>
            </div>
          )
        }
      }
      </Translation>
    );
  }
}

export const FrontPageContainer = withRouter(FrontPage)
export default FrontPageContainer
