import React from 'react';
import './SidebarContainer.css';
import SidebarLink from '../../Components/Sidebar/SidebarLink';
import LanguageSelector from '../../Components/LanguageSelector/LanguageSelector';
import axios from 'axios';

class SidebarContainer extends React.Component<{}, {}> {

  private Logout() {
    axios.post(process.env.REACT_APP_USERS + "/logout")
    .then(res => {
      console.log(res);
      })
    .catch(res => {
      console.log(res);
    });
  }

  public render() {
    return (
      <div className="sidebar-div">
        <div>
          <SidebarLink destination="" linkText="FRONT_PAGE" />
          <SidebarLink destination="users" linkText="USERS" />
          <SidebarLink destination="customers" linkText="CUSTOMERS" />
          <SidebarLink destination="waste-types" linkText="WASTE_TYPES" />
          <SidebarLink destination="waste-items" linkText="WASTE_ITEMS" />
          <SidebarLink destination="facilities" linkText="WASTEFACILITIES" />
          <SidebarLink destination="reservations" linkText="RESERVATION_CALENDAR" />
          <SidebarLink destination="reviews" linkText="REVIEWS" />
          <SidebarLink destination="user-guides" linkText="USER_GUIDES" />
        </div>
        <div>
          <SidebarLink destination="" linkText="LOGOUT" clickFunction={() => this.Logout()} defaultPath="" showTo="everyone" />
          <LanguageSelector />
        </div>
      </div>
    );
  }
}

export default SidebarContainer;
